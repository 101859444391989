jQuery(function($) {

  $(document).ready(function() {

    function toggleConsultationModal() {
      $('body').toggleClass('consultation-overlay-open');
    }

    $('.consultation-toggle, [href="#consultation-toggle"]').click( function(e) {
      e.preventDefault();
      toggleConsultationModal();
    });

    $('.menu-toggle').click( function() {
      $('body').toggleClass('menu-open');
    });

    $('.schema-faq-section').accordion( {
      animate: 200,
      header: ".schema-faq-question",
      heightStyle: "content",
      collapsible: true,
      active: false,
      icons: false
    });

    $('.cs-bas').each(function (idx, item) {
       var carouselId = "cs-bas-" + idx;
       this.id = carouselId;
       $('#'+carouselId+' .ba-carousel').on('init', function(event, slick){
         slick.$slider.css('--slide-max-width', slick.$slider.innerWidth() + 'px' );
         $( window ).resize( function() {
          slick.$slider.css('--slide-max-width', slick.$slider.innerWidth() + 'px' );
         });
       });
       $('#'+carouselId+' .ba-carousel').slick({
           slide: "#" + carouselId +" .image",
           appendArrows: "#" + carouselId + " .slick-nav",
           infinite: false,
           swipeToSlide: true,
           variableWidth: true,
           prevArrow: '<div class="slick-prev"><i class="fas fa-angle-left"></i></div>',
           nextArrow: '<div class="slick-next"><i class="fas fa-angle-right"></i></div>'
       });
    });

  });

});